import styled from '@emotion/styled/macro';
import { space } from 'styled-system';
import { rem, minWidth } from '@feast-it/pesto';

export const ClientLogo = styled.div`
  ${space}
  filter: grayscale(1);
  opacity: 0.3;
  display: inline-flex;
  justify-content: center;

  ${({ invertDesktop, theme }) =>
    invertDesktop &&
    `
    ${minWidth(theme.breakpoints.m)} {
      filter: grayscale(1) brightness(1) invert(1);
      opacity: 0.8;
    }
  `}

  img {
    max-height: ${rem(50)};
  }
`;
