import React from 'react';
import { Flex, Box, Text, Carousel, Grid, base } from '@feast-it/pesto';

import ClientLogo from '../../components/ClientLogo';
import VisuallyHidden from '../../components/VisuallyHidden';
import { useBreakpoint } from '../../hooks/media';

const RecommendationItem = itemData => {
  const item = {
    image: itemData?.client_image?.url,
    alt: itemData?.client_image?.alt,
    quote: itemData?.client_quote,
  };

  return (
    <Flex
      py={{ _: 8, m: 10 }}
      px={9}
      flexDirection="column"
      alignItems="center"
    >
      <ClientLogo src={item.image} alt={item.alt || ''} />

      <Text
        variant="large"
        fontStyle="italic"
        textAlign="center"
        color="blueDarkOpaque"
        mt={2}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: item.quote,
          }}
        />
      </Text>
    </Flex>
  );
};

const RecommendationsEditable = rawData => {
  const isDesktop = useBreakpoint(base.breakpoints.m);
  const items = rawData?.items;
  const isCarousel = items?.length > 3 || !isDesktop;

  return (
    <Box as="section" px={{ m: 'gutter.m' }} mt={{ m: 6 }}>
      <VisuallyHidden>
        <h2>Recommendations</h2>
      </VisuallyHidden>

      <Box bg="backDrop" borderRadius={{ m: 3 }}>
        {isCarousel ? (
          <Carousel
            options={{
              perView: 4,
              breakpoints: {
                1600: {
                  perView: 3,
                },
                768: {
                  perView: 2,
                },
                576: {
                  perView: 1,
                },
              },
            }}
            arrowOptions={{ variant: 'line', px: 2 }}
            dots={false}
          >
            {items.map((itemData, i) => (
              <RecommendationItem
                key={`editable-recommendation-carousel-${i}`}
                {...itemData}
              />
            ))}
          </Carousel>
        ) : (
          <Grid gridTemplateColumns={`repeat(${items.length}, 1fr)`}>
            {items.map((itemData, i) => (
              <RecommendationItem
                key={`editable-recommendation-grid-${i}`}
                {...itemData}
              />
            ))}
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default RecommendationsEditable;
