import React from 'react';
import PropTypes from 'prop-types';

import * as Styled from './styles';

const ClientLogo = ({ src, alt, invertDesktop, ...props }) => {
  return (
    <Styled.ClientLogo invertDesktop={invertDesktop} {...props}>
      <img src={`${src}&h=100`} alt={alt} loading="lazy" />
    </Styled.ClientLogo>
  );
};

ClientLogo.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  invertDesktop: PropTypes.bool,
};

export default ClientLogo;
